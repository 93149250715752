.App-title {
  margin-bottom: 0;
}
.App-title span {
  display: inline-block;
}
.App-main {
  font-size: calc(16px + 0.5vmin);
  line-height: 1.58;
}
.App-main p,
.App-main ul {
  margin-top: 2rem;
}