.App-header {
  display: flex;
  align-items: center;
  margin: 2rem 0;
  padding: 1.5rem 0;
}
.App-menu {
  font-size: calc(16px + 0.5vmin);
}
.App-menu .link {
  color: #CC0000;
  line-height: 1.58;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  text-decoration: none;
}
.App-menu .link:hover,
.App-menu .link.active {
  border-bottom: 1px solid #CC0000;
}
.App-menu .link.active {
  font-weight: 700;
}