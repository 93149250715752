html {
  background: #202020;
  color: #fff;
  font-size: 14px;
}
.red {
  color: #CC0000;
  text-shadow: 2px 2px 5px #000000;
}
.App {
  font-family: 'Fira Code', monospace;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App-wrapper {
  width: 80%;
  max-width: 980px;
  padding: 1rem 2rem;
}